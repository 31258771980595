.ant-layout-header{
    height: 54px;
    background-color: white;
    display: inline-flex;
    width: 100%;
    padding: 0 20px !important;
    justify-content: space-between;
    overflow: hidden;
}

.ant-layout-header > div{
    display: flex;
}

.ant-layout-content{
    background-color: #fff;
    padding: 0px 20px;
}

.ant-layout-footer{
    background-color: white;
    text-align: center;
    font-weight: 400;
}

.ant-tag{
    cursor: pointer;
}

.menu{
    height: 54px;
    border: none;

    li{
        width: 96px;
        display: flex !important;
        justify-content: center;
    }
}

.responsive_menu{
    height: 54px;
    border: none;
    width: 100%;
    margin-left: 15px;

    li{
        width: 55px;
        display: flex !important;
        justify-content: center;
    }
}

.ant-checkbox-group-item{
    display: flex;
    font-weight: 600;
    color: #000;
    margin: 5px 10px !important;
}

.icon{
    margin-right: 5px;
}

.pointer{
    cursor: pointer;
}

.ant-radio-inner::after{
    background-color: #000;
    border: 1px solid #000;
}

.ant-radio-checked .ant-radio-inner{
    border-color: #000;
}

.ant-checkbox-checked .ant-checkbox-inner{
    background-color: #000;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
    border-color: #000;
}

.ant-radio-wrapper:hover .ant-radio-inner, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{
    border-color: #000;
}

.ant-select-selection-item, .ant-cascader-picker{
    font-weight: 400;
}

.listcourse .ant-list-item-meta-content{
    padding-top: 3px;
}

.detail .ant-list-item-meta-content{
    padding-top: 0px;
}

.detail .ant-list-item-meta-avatar{
    margin-top: 4px;
}

.alesson .ant-list-item-meta-avatar{
    margin-top: 0px;
}

.nodata{
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-menu-item:hover, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, 
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected, 
.ant-menu-horizontal > .ant-menu-item-selected a{
    color: #003ECD;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #003ECD;
}

.responsive_menu  .ant-menu-item:hover::after,
.responsive_menu  .ant-menu-submenu:hover::after,
.responsive_menu  .ant-menu-item-active::after,
.responsive_menu  .ant-menu-submenu-active::after,
.responsive_menu  .ant-menu-item-open::after,
.responsive_menu  .ant-menu-submenu-open::after,
.responsive_menu  .ant-menu-item-selected::after,
.responsive_menu  .ant-menu-submenu-selected::after {
  border-bottom: none !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #003ECD;
}

.responsive_menu .ant-menu-item::after,
.responsive_menu .ant-menu-submenu::after {
  border-bottom: none;
}

.ant-menu-horizontal > .ant-menu-item a:hover {
    color: #003ECD;
  }

.section_card{
    margin-bottom: 15px;
    background-color: #fff;
}

.itemTop{
    padding-top: 20px;
}

.center{
    text-align: center;
    position: relative;
    top: 100px;

    div{
        line-height: 40px;
    }
}

.ant-btn-primary:hover, .ant-btn-primary:focus, .ant-btn-primary{
    color: #fff;
    border-color: #008BD0;
    background-color: #008BD0;
}

.logo{
    position: relative;
    top: 15px;
    padding-right: 10px;
}

.responsive_logo{
    position: relative;
    top: 10px;
    width: 30px;
    height: 30px;
}

.ant-modal-confirm .ant-modal-confirm-btns{
    float: none;
    text-align: center;
}

.textCenter .ant-modal-confirm-content{
    text-align: center;
}

.ant-carousel .slick-dots li.slick-active button{
    background-color: #003ECD;
}

.ant-carousel .slick-dots li button{
    background-color: gray;
}

.ant-carousel .slick-dots-bottom{
    bottom: -22px;
}

.ant-list-item-action{
    margin-left: 5px;
}

.ant-btn-text{
    padding: 5px 10px;
}

.line .ant-list-item-meta-content{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.itemAvatar{
    height: 64px;
    width: 104px;
}

.ant-list-item-meta-description {
    font-size: 14px;
    line-height: 1.5715;
}

.navItem{
    height: 100%;
    position: relative;
    display: block;
}

.navItemText{
    position: absolute;
    top: 15px;
    font-size: 11px;
    left: 0px;
    right: 0px;
    text-align: center;
}

.ant-select-selection-placeholder{
    font-weight: 300;
}

::-webkit-scrollbar {
width: 5px;
height: 5px;
}
       
::-webkit-scrollbar-thumb {
background: #bbbbbb;
background: rgba(0, 0, 0, 0.15);
border-radius: 5px;
}
    
::-webkit-scrollbar-track {
background: #dddddd;
background: rgba(0, 0, 0, 0.05);
}

.home.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
    border-bottom: none;
}

.ant-breadcrumb a:hover {
    color: #003ECD;
  }

.ant-btn{
    box-shadow: none;
}